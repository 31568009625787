import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import queryString from 'query-string';

import readCookie from 'common/services/readCookie';
import introspectionQueryResultData from 'common/fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

function customFetch(uri, options) {
  const gqlParams = JSON.parse(options.body);
  const queryParams = {
    operationName: gqlParams.operationName,
  };
  uri += `?${queryString.stringify(queryParams)}`;
  return fetch(uri, options);
}

export default function createApolloClient({ linkOptions, state } = {}) {
  function customFetchWithCsrfToken(uri, options) {
    options.headers['X-CSRFToken'] = readCookie('csrftoken');
    return customFetch(uri, options);
  }

  const httpLink = createHttpLink(Object.assign({
    // uri: 'https://dev.bigbox.com.ar:3000/graphql/',
    uri: '/graphql/',
    credentials: 'same-origin',
    fetch: customFetchWithCsrfToken,
  }, linkOptions));

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error) => {
        return error.result.error && error.result.error === 'CSRF_EXCEPTION' && error.statusCode === 409;
      },
    },
  });

  const cache = new InMemoryCache({ fragmentMatcher });
  if (state) {
    cache.restore(state);
  }

  const link = retryLink.concat(httpLink);

  return new ApolloClient({
    link,
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
    ssrMode: process.env.SSR_ENV === 'server',
  });
}
